import { compareDesc } from '@/store/store';

export default {
	getAllMedicalOrgs() {
		let params = {
			filters: [],
			sortOption: {
				fieldName: 'RegionName',
				sortType: 2
			},
			pageSize: 999999,
			currentPage: 0
		};
		return new Promise((resolve, reject) => {
			this.$http
				.post('MedicalOrgs/GetMedicalOrgs', params)
				.then((res) => {
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	getFeedbacks(parametrs) {
		return new Promise((resolve, reject) => {
			this.$http
				.post('Anketa/GetFeedbacks', parametrs)
				.then((result) => {
					result.data.data.forEach((x) => {
						x.feedBackList.sort((a, b) => compareDesc(a.feedBackDate, b.feedBackDate));
					});

					resolve(result);
				})
				.catch((error) => {
					reject(error);
				});
		});
	}
};
